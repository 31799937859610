import React from 'react';
import c from 'classnames';

import hours from '../../data/hours.json';

import styles from './footer.module.css';

const DayHours = ([day, hours]) => (
  <tr key={day}>
    <td className="text-white-50 pl-0">{day}</td>
    <td>{hours}</td>
  </tr>
);

export default function Footer() {
  return (
    <footer className={c('pt-5 pb-5', styles.footer)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-xs-12 pb-5 pb-lg-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2555.978791444306!2d-96.88820548427904!3d50.16153327943657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52eaf5234548e72d%3A0x8544b3d3d608ee41!2sEaston%20Place%20Medical%20Centre!5e0!3m2!1sen!2sca!4v1598809923217!5m2!1sen!2sca" style={{ width: '100%', height: 300 }} frameBorder="0" allowFullScreen aria-hidden="false" title="Easton Place on Google Maps"></iframe>
          </div>
          <div className="col-lg-4 col-xs-12 pb-4 pb-lg-0 pl-lg-5">
            <h5>Contact</h5>
            <p className="pr-5 m-0 mb-5">
              <a href="tel:12044824044">
                <i className="fa fa-phone mr-3" />(204) 482-4044
              </a>
              <br />
              <a href="mailto:bookings@eastonplace.ca">
                <i className="fa fa-envelope mr-3" />bookings@eastonplace.ca
              </a>
              <br />
              <a
                href="https://www.facebook.com/eastonplacemb/"
                target="_blank"
                title="Easton Place on Facebook"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square mr-3" />eastonplacemb
              </a>
            </p>
            <h5>Location</h5>
            <p className="text-white-50">
              15 Wersch Street
              <br />
              Selkirk, Manitoba, Canada
              <br />
              R1A 2B2
            </p>
          </div>
          <div className="col-lg-4 col-xs-12 pb-4 pb-lg-0">
            <h5 className="mt-lg-0 mt-sm-4">Hours of Operation</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                {Object.entries(hours).map(DayHours)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </footer>
  );
}