import React, { useState } from 'react';
import classnames from 'classnames';
import Link from 'gatsby-link';

import LogoImage from '../../static/img/logo.svg';
import styles from './navigation.module.css';

export default () => {
  const [showMenu, setShowMenu] = useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <nav className={classnames(styles.navigation, 'navbar-light', 'py-3', 'px-4', 'px-md-5')}>
      <div className="d-flex align-items-center justify-content-between">
        <Link
          className="m-0 green d-flex align-items-center mx-3"
          to="/"
          title="Homepage"
        >
          <div className="d-flex align-items-center">
            <img src={LogoImage} className="mr-4" style={{ width: 50 }} alt="Easton Place logo" />
            <div>
              <p className="text-small m-0 d-none d-md-block">Easton Place Medical Centre</p>
              <p className="text-small m-0 d-block d-md-none">Easton Place</p>
              <p className="text-black-50 m-0" style={{ fontSize: '.8em', lineHeight: '1em' }}>Selkirk, Manitoba</p>
            </div>
          </div>
        </Link>
        <button className="navbar-toggler d-lg-none" type="button" onClick={onClick}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="d-none d-lg-flex">
          <Link
            className="m-0 green d-flex align-items-center mx-3"
            to="/walk-in"
            title="Walk-in Clinic"
          >
            Walk-in Clinic
          </Link>
          <Link
            className="m-0 green d-flex align-items-center mx-3"
            to="/pharmacy"
            title="Pharmacy"
          >
            Pharmacy
          </Link>
          <a
            className="m-0 green d-flex align-items-center mx-3"
            href="http://redriverdental.ca"
            title="Red River Dental"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dental
          </a>
          <a
            className="m-0 green d-flex align-items-center mx-3"
            href="https://eastonplacemassage-interlakelymphclinic.noterro.com/"
            title="Interlake Lymph Clinic"
            target="_blank"
            rel="noopener noreferrer"
          >
            Massage
          </a>
          <a
            className="m-0 green d-flex align-items-center mx-3"
            href="https://horizonhearing.com"
            title="Horizon Hearing Centres"
            target="_blank"
            rel="noopener noreferrer"
          >
            Audiology
          </a>
        </div>
      </div>
      {showMenu && (
        <div className="d-block mt-3">
          <Link
            className="m-0 py-2 green d-flex align-items-center mx-3"
            to="/walk-in"
            title="Walk-in Clinic"
          >
            Walk-in Clinic
          </Link>
          <Link
            className="m-0 py-2 green d-flex align-items-center mx-3"
            to="/pharmacy"
            title="Pharmacy"
          >
            Pharmacy
          </Link>
          <a
            className="m-0 py-2 green d-flex align-items-center mx-3"
            href="http://redriverdental.ca"
            title="Red River Dental"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dental
          </a>
          <a
            className="m-0 py-2 green d-flex align-items-center mx-3"
            href="https://eastonplacemassage-interlakelymphclinic.noterro.com/"
            title="Interlake Lymph Clinic"
            target="_blank"
            rel="noopener noreferrer"
          >
            Massage
          </a>
          <a
            className="m-0 py-2 green d-flex align-items-center mx-3"
            href="https://horizonhearing.com"
            title="Horizon Hearing Centres"
            target="_blank"
            rel="noopener noreferrer"
          >
            Audiology
          </a>
        </div>
      )}
    </nav>
  );
}
